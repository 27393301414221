// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/folder_closed_logo.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../img/folder_open_logo.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../img/delete.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../img/edit_logo.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../img/add_new_upsell.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "#no-data[data-v-cf6e978a] {\n  background-color: transparent;\n}\n.upsell-group-table .v-data-table tbody tr[data-v-cf6e978a]:hover:not(.v-datatable__expand-row) {\n  background: white;\n}\n.upsell-group-table .v-data-table tbody tr td[data-v-cf6e978a] {\n  color: #585858 !important;\n  vertical-align: middle;\n  height: 41px;\n  font-size: 14px;\n  padding-left: 30px;\n  width: 250px;\n}\n.row__title[data-v-cf6e978a] {\n  padding-left: 12px;\n}\n.row__icon[data-v-cf6e978a] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  width: 18px;\n  height: 18px;\n}\n.row__icon--active[data-v-cf6e978a] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n}\n.row--delete[data-v-cf6e978a] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-repeat: no-repeat;\n  background-size: 20px;\n  cursor: pointer;\n  width: 20px;\n  height: 20px;\n  margin-top: -2px;\n}\n.row--edit[data-v-cf6e978a] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  background-repeat: no-repeat;\n  background-size: 14px;\n  cursor: pointer;\n  margin-left: 15px;\n  width: 20px;\n  height: 20px;\n  padding: 5px;\n}\n.action-row[data-v-cf6e978a] {\n  padding: 15px;\n  cursor: pointer;\n  align-items: center;\n}\n.action-row__title[data-v-cf6e978a] {\n  margin-left: 10px;\n  color: #93BD20 !important;\n  font-family: \"Roboto\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n}\n.upsell-logo[data-v-cf6e978a] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n  background-repeat: no-repeat;\n  margin-left: 15px;\n  flex-grow: 0 !important;\n  width: 18px;\n  height: 18px;\n  background-size: contain;\n}", ""]);
// Exports
module.exports = exports;
