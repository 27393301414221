<template>
  <v-col md="12">
    <div v-bar class="upsell-group-expansion">
      <v-card class="elevation-1">
        <v-list v-if="items.length" hide-actions class="el2">
          <v-list-item class="tile">
            <v-list-item-action></v-list-item-action>
            <v-row class="mb-0" >
              <v-col md="3">
                <v-list-item-title>Item Name</v-list-item-title>
              </v-col>
              <v-col md="3">
                <v-list-item-title>Item ID</v-list-item-title>
              </v-col>
              <v-col md="3">
                <v-list-item-title>Cost</v-list-item-title>
              </v-col>
              <v-col md="3">
                <v-list-item-title></v-list-item-title>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item class="tile" v-for="item in items" :key="item.title">
                <v-list-item-action>
                  <div class="upsell-logo"></div>
                </v-list-item-action>
                <v-row class="mb-0" >
                  <v-col md="3">
                    <v-list-item-title>{{ item.inventoryItemName }}</v-list-item-title>
                  </v-col>
                  <v-col md="3">
                    <v-list-item-title>{{ item.InventoryItemID }}</v-list-item-title>
                  </v-col>
                  <v-col md="3">
                    <v-list-item-title>{{ item.costDisplay }}</v-list-item-title>
                  </v-col>
                  <v-col md="3">
                    <v-list-item-title>
                      <div @click="deleteItem(item)" class="delete-logo"/>
                    </v-list-item-title>
                  </v-col>
                </v-row>
          </v-list-item>
        </v-list>
        <v-card-text v-else>No items assigned.</v-card-text>
      </v-card>
    </div>
  </v-col>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";
@import "~css/vuebar.css";

.upsell-group-expansion {
  .tile {
    border-bottom: 1px solid #e4e4e4;
  }
}
.v-list {
  margin-right: 5px;

  &__tile {
    &__title {
      color: #585858 !important;
      font-size: 14px;
    }
  }
}

.v-card {
  &__title {
    color: #585858 !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
  }

  &__text {
    color: #585858 !important;
    font-size: 14px;
  }
}

.upsell-logo {
  @include background-image("", "add_new_upsell.svg", "../../img/");

  flex-grow: 0 !important;
  width: spacing(sm);
  height: spacing(sm);
  background-size: contain;
}
</style>
