import { mapActions, mapGetters } from "vuex";
import clone from "clone";
import _ from 'lodash'

export const UpsellDataHelper = {
    computed: {
        currentItems() {
            let upsellItems = this.currentUpsellItems
            let exclusionaryItems = this.currentExclusionaryUpsellItems

            let combined = upsellItems.concat(exclusionaryItems)

            let keyed = combined.map(el => {
                return {
                    ...el,
                    ID: `${el.TargetID}-${el.exclusionary}`,
                }
            })

            return _.sortBy(keyed, ['LevelID', (el) => el.display])

        },
        currentUpsellItems() {
            if (
                this.upsellOverviewForStore.length == 0 ||
                this.store == undefined ||
                this.productsMap == undefined ||
                this.productCategories.length == 0
            )
                return [];
            let cloned = clone(this.upsellOverviewForStore);
            return cloned.map(level => {
                if (level.LevelName == "STORE") {
                    return {
                        ...level,
                        display: `Store - ${this.store.storeName}`,
                        targetName: this.store.storeName,
                        items: this.populatedUpsellAddonItems(level.UpsellAddonItems)
                    };
                } else if (level.LevelName == "CATEGORY") {
                    let category = this.productCategories.find(
                        el => el.inventoryTitleID == level.TargetID
                    );
                    let display =
                        category === undefined
                            ? "Unknown Category"
                            : `Category ${category.inventoryTitleDescription}`;
                    return {
                        ...level,
                        display,
                        targetName: category.inventoryTitleDescription,
                        items: this.populatedUpsellAddonItems(level.UpsellAddonItems)
                    };
                } else if (level.LevelName == "ITEM") {
                    let item = this.productsMap[`${level.TargetID}`];
                    let display =
                        item === undefined ? "Unknown Item" : item.inventoryItemName;
                    return {
                        ...level,
                        display,
                        targetName: item === undefined ? "Missing!" : item.inventoryItemName,
                        items: this.populatedUpsellAddonItems(level.UpsellAddonItems)
                    };
                } else {
                    return level;
                }
            }).map(el => {
                return {
                    ...el,
                    exclusionary: false
                }
            });
        },
        currentExclusionaryUpsellItems() {
            if (
                this.exlusionaryUpsellOverviewForStore.length == 0 ||
                this.store == undefined ||
                this.productsMap == undefined ||
                this.productCategories.length == 0
            )
                return [];
            let cloned = clone(this.exlusionaryUpsellOverviewForStore);
            return cloned.map(level => {
                if (level.LevelName == "STORE") {
                    return {
                        ...level,
                        display: `Store - ${this.store.storeName}`,
                        targetName: this.store.storeName,
                        items: this.populatedUpsellAddonItems(level.UpsellAddonItems)
                    };
                } else if (level.LevelName == "CATEGORY") {
                    let category = this.productCategories.find(
                        el => el.inventoryTitleID == level.TargetID
                    );
                    let display =
                        category === undefined
                            ? "Unknown Category"
                            : `Category ${category.inventoryTitleDescription}`;
                    return {
                        ...level,
                        display,
                        targetName: category.inventoryTitleDescription,
                        items: this.populatedUpsellAddonItems(level.UpsellAddonItems)
                    };
                } else if (level.LevelName == "ITEM") {
                    let item = this.productsMap[`${level.TargetID}`];
                    let display =
                        item === undefined ? "Unknown Item" : item.inventoryItemName;
                    return {
                        ...level,
                        display,
                        targetName: item === undefined ? "Missing!" : item.inventoryItemName,
                        items: this.populatedUpsellAddonItems(level.UpsellAddonItems)
                    };
                } else {
                    return level;
                }
            }).map(el => {
                return {
                    ...el,
                    display: `${el.display} - EXCLUDED ITEMS`,
                    exclusionary: true
                }
            });
        },
        currentUpsellGroups() {
            if (
                this.upsellGroupsForStore.length == 0 ||
                this.store == undefined ||
                this.productsMap == undefined ||
                this.productCategories.length == 0
            )
                return [];

            var groups = this.upsellGroupsForStore.map(el => {
                return {
                    ...el,
                    items: this.populatedUpsellAddonItems(el.Items)
                }
            })
            groups.sort((a, b) => a.GroupID - b.GroupID)
            return groups
        },
        ...mapGetters(["store", "productCategories", "productsMap"]),
        ...mapGetters("UpsellStore", ["upsellOverviewForStore", "upsellGroupsForStore", "exlusionaryUpsellOverviewForStore"])
    },
    methods: {
        populatedUpsellAddonItems(items) {
            return items.map(item => {
                let product = this.productsMap[`${item.InventoryItemID}`];
                if (product === undefined) return item;
                return {
                    ...item,
                    ...product,
                };
            });
        },
        ...mapActions("UpsellStore", ["getStoreUpsellOverview"])
    },
    mounted() {
        this.getStoreUpsellOverview(this.$route.params.store);
    }
}

export const AddonDataHelper = {
    computed: {
        currentItems() {
            let upsellItems = this.currentAddonItems
            let exclusionaryItems = this.currentExclusionaryAddonItems

            let combined = upsellItems.concat(exclusionaryItems)

            let keyed = combined.map(el => {
                return {
                    ...el,
                    ID: `${el.TargetID}-${el.exclusionary}`,
                }
            })

            return _.sortBy(keyed, ['LevelID', (el) => el.display])

        },
        currentExclusionaryAddonItems() {
            if (
                this.exclusionaryAddonOverviewForStore.length == 0 ||
                this.store == undefined ||
                this.productsMap == undefined ||
                this.productCategories.length == 0
            )
                return [];
            let cloned = clone(this.exclusionaryAddonOverviewForStore);
            return cloned.map(level => {
                if (level.LevelName == "STORE") {
                    return {
                        ...level,
                        display: `Store - ${this.store.storeName}`,
                        targetName: this.store.storeName,
                        items: this.populatedUpsellAddonItems(level.UpsellAddonItems)
                    };
                } else if (level.LevelName == "CATEGORY") {
                    let category = this.productCategories.find(
                        el => el.inventoryTitleID == level.TargetID
                    );
                    let display =
                        category === undefined
                            ? "Unknown Category"
                            : `Category ${category.inventoryTitleDescription}`;
                    return {
                        ...level,
                        display,
                        targetName: category.inventoryTitleDescription,
                        items: this.populatedUpsellAddonItems(level.UpsellAddonItems)
                    };
                } else if (level.LevelName == "ITEM") {
                    let item = this.productsMap[`${level.TargetID}`];
                    let display =
                        item === undefined ? "Unknown Item" : item.inventoryItemName;
                    return {
                        ...level,
                        display,
                        targetName: item === undefined ? "Missing!" : item.inventoryItemName,
                        items: this.populatedUpsellAddonItems(level.UpsellAddonItems)
                    };
                } else {
                    return level;
                }
            }).map(el => {
                return {
                    ...el,
                    display: `${el.display} - EXCLUDED ITEMS`,
                    exclusionary: true
                }
            })
        },
        currentAddonItems() {
            if (
                this.addonOverviewForStore.length == 0 ||
                this.store == undefined ||
                this.productsMap == undefined ||
                this.productCategories.length == 0
            )
                return [];
            let cloned = clone(this.addonOverviewForStore);
            return cloned.map(level => {
                if (level.LevelName == "STORE") {
                    return {
                        ...level,
                        display: `Store - ${this.store.storeName}`,
                        targetName: this.store.storeName,
                        items: this.populatedUpsellAddonItems(level.UpsellAddonItems)
                    };
                } else if (level.LevelName == "CATEGORY") {
                    let category = this.productCategories.find(
                        el => el.inventoryTitleID == level.TargetID
                    );
                    let display =
                        category === undefined
                            ? "Unknown Category"
                            : `Category ${category.inventoryTitleDescription}`;
                    return {
                        ...level,
                        display,
                        targetName: category.inventoryTitleDescription,
                        items: this.populatedUpsellAddonItems(level.UpsellAddonItems)
                    };
                } else if (level.LevelName == "ITEM") {
                    let item = this.productsMap[`${level.TargetID}`];
                    let display =
                        item === undefined ? "Unknown Item" : item.inventoryItemName;
                    return {
                        ...level,
                        display,
                        targetName: item === undefined ? "Missing!" : item.inventoryItemName,
                        items: this.populatedUpsellAddonItems(level.UpsellAddonItems)
                    };
                } else {
                    return level;
                }
            }).map(el => {
                return {
                    ...el,
                    exclusionary: false
                }
            });
        },
        currentUpsellGroups() {
            if (
                this.upsellGroupsForStore.length == 0 ||
                this.store == undefined ||
                this.productsMap == undefined ||
                this.productCategories.length == 0
            )
                return [];

            var groups = this.upsellGroupsForStore.map(el => {
                return {
                    ...el,
                    items: this.populatedUpsellAddonItems(el.Items)
                }
            })
            groups.sort((a, b) => a.GroupID - b.GroupID)
            return groups
        },
        ...mapGetters(["store", "productCategories", "productsMap"]),
        ...mapGetters("UpsellStore", ["addonOverviewForStore", "upsellGroupsForStore", "exclusionaryAddonOverviewForStore"])
    },
    methods: {
        populatedUpsellAddonItems(items) {
            return items.map(item => {
                let product = this.productsMap[`${item.InventoryItemID}`];
                if (product === undefined) return item;
                return {
                    ...item,
                    ...product,
                };
            });
        },
        ...mapActions("UpsellStore", ["getStoreAddonOverview"])
    },
    mounted() {
        this.getStoreAddonOverview(this.$route.params.store);
    }
}