// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!css/vuebar.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/add_new_upsell.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".upsell-group-expansion .tile[data-v-03ff58a2] {\n  border-bottom: 1px solid #e4e4e4;\n}\n.v-list[data-v-03ff58a2] {\n  margin-right: 5px;\n}\n.v-list__tile__title[data-v-03ff58a2] {\n  color: #585858 !important;\n  font-size: 14px;\n}\n.v-card__title[data-v-03ff58a2] {\n  color: #585858 !important;\n  font-family: \"Roboto\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n}\n.v-card__text[data-v-03ff58a2] {\n  color: #585858 !important;\n  font-size: 14px;\n}\n.upsell-logo[data-v-03ff58a2] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  flex-grow: 0 !important;\n  width: 18px;\n  height: 18px;\n  background-size: contain;\n}", ""]);
// Exports
module.exports = exports;
