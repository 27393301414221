<template>
  <div>
    <ContextTitle :passedActions="titleBarActions" title="Upsell & Addon Groups"/>
    <v-container class="fill-height pa-0" fluid>
      <v-row class="pa-0" >
        <v-col md="12">
          <v-row class="action-row mt-0" @click.stop="onAdd">
            <div class="upsell-logo"/>
            <div class="action-row__title">Add Group</div>
          </v-row>
          <v-data-table
              class="upsell-group-table"
              v-if="currentUpsellGroups.length"
              :items="currentUpsellGroups"
              :headers="headers"
              :expand="expand"
              item-key="GroupID"
              disable-pagination
              hide-default-footer
              hide-default-header
          >
            <template v-slot:no-data>
              <div id="no-data"/>
            </template>
            <template v-slot:item="{expand, isExpanded, item}">
              <tr @click="expand(!isExpanded)">
                <td>
                  <v-row class="name-row pt-3" no-gutters align-content="center">
                    <div class="row__icon" :class="{'row__icon--active': !isExpanded}"></div>
                    <div class="row__title">{{ item.GroupName }}</div>
                    <div
                        v-if="!isExpanded"
                        class="row--edit"
                        @click.stop="onEditItem(item)"
                    ></div>
                  </v-row>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="props">
              <UpsellGroupExpansion :items="props.item.items"/>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import ContextTitle from "components/context_title.vue";
import UpsellGroupExpansion from "components/upsell/upsell_group_expansion.vue";

import { UpsellDataHelper } from "mixins/upsell.js";
import { mapActions, mapGetters } from "vuex";
import clone from "clone";

export default {
    name: "UpsellGroup",
    data() {
        return {
            headersLength: 2,
            titleBarActions: [],
            rowsPerPageItems: [10, 20, 30, 40, 100],
            pagination: {
                rowsPerPage: 100,
                page: 1
            },
            expand: false,
            headers: [
                {
                    sortable: false,
                    text: "",
                    value: "name"
                }
            ]
        };
    },
    mixins: [UpsellDataHelper],
    components: {
        ContextTitle,
        UpsellGroupExpansion
    },
    computed: {
        existingTargetSet() {
            if (this.currentUpsellItems === undefined) return new Set();
            return new Set(this.currentUpsellItems.map(el => el.TargetID));
        }
    },
    methods: {
        onEditItem(item) {
            this.$router.push({
                name: "upsell-group-edit",
                params: {
                    GroupID: item.GroupID
                }
            });
        },
        onAdd() {
            this.$router.push({
                name: "upsell-group-create",
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

#no-data {
  background-color: transparent;
}
.upsell-group-table {
  .v-data-table {
    tbody {
      tr:hover:not(.v-datatable__expand-row) {
        background: white;
      }
      tr {
        td {
          color: #585858 !important;
          vertical-align: middle;
          height: 41px;
          font-size: 14px;
          padding-left: 30px;
          width: 250px;
        }
      }
    }
  }
}
.row {
  &__title {
    padding-left: spacing(xs);
  }

  &__icon {
    @include background-image("", "folder_closed_logo.svg", "../../img/");
    background-size: contain;
    width: spacing(sm);
    height: spacing(sm);

    &--active {
      @include background-image("", "folder_open_logo.svg", "../../img/");
    }
  }

  &--delete {
    @include background-image("", "delete.svg", "../../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
  &--edit {
    @include background-image("", "edit_logo.svg", "../../img/");
    background-size: 14px;
    cursor: pointer;

    margin-left: 15px;
    width: 20px;
    height: 20px;
    padding: 5px;
  }
}

.action-row {
  padding: 15px;
  cursor: pointer;
  align-items: center;
  &__title {
    margin-left: 10px;
    color: $brand_color !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
  }
}
.upsell-logo {
  @include background-image("", "add_new_upsell.svg", "../../img/");
  margin-left: 15px;
  flex-grow: 0 !important;
  width: spacing(sm);
  height: spacing(sm);
  background-size: contain;
}
</style>
