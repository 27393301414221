var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-col", { attrs: { md: "12" } }, [
    _c(
      "div",
      {
        directives: [{ name: "bar", rawName: "v-bar" }],
        staticClass: "upsell-group-expansion",
      },
      [
        _c(
          "v-card",
          { staticClass: "elevation-1" },
          [
            _vm.items.length
              ? _c(
                  "v-list",
                  { staticClass: "el2", attrs: { "hide-actions": "" } },
                  [
                    _c(
                      "v-list-item",
                      { staticClass: "tile" },
                      [
                        _c("v-list-item-action"),
                        _c(
                          "v-row",
                          { staticClass: "mb-0" },
                          [
                            _c(
                              "v-col",
                              { attrs: { md: "3" } },
                              [_c("v-list-item-title", [_vm._v("Item Name")])],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { md: "3" } },
                              [_c("v-list-item-title", [_vm._v("Item ID")])],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { md: "3" } },
                              [_c("v-list-item-title", [_vm._v("Cost")])],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { md: "3" } },
                              [_c("v-list-item-title")],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.items, function (item) {
                      return _c(
                        "v-list-item",
                        { key: item.title, staticClass: "tile" },
                        [
                          _c("v-list-item-action", [
                            _c("div", { staticClass: "upsell-logo" }),
                          ]),
                          _c(
                            "v-row",
                            { staticClass: "mb-0" },
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.inventoryItemName)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.InventoryItemID)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.costDisplay)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("v-list-item-title", [
                                    _c("div", {
                                      staticClass: "delete-logo",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(item)
                                        },
                                      },
                                    }),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _c("v-card-text", [_vm._v("No items assigned.")]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }