var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: {
          passedActions: _vm.titleBarActions,
          title: "Upsell & Addon Groups",
        },
      }),
      _c(
        "v-container",
        { staticClass: "fill-height pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "pa-0" },
            [
              _c(
                "v-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "action-row mt-0",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onAdd.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "upsell-logo" }),
                      _c("div", { staticClass: "action-row__title" }, [
                        _vm._v("Add Group"),
                      ]),
                    ]
                  ),
                  _vm.currentUpsellGroups.length
                    ? _c("v-data-table", {
                        staticClass: "upsell-group-table",
                        attrs: {
                          items: _vm.currentUpsellGroups,
                          headers: _vm.headers,
                          expand: _vm.expand,
                          "item-key": "GroupID",
                          "disable-pagination": "",
                          "hide-default-footer": "",
                          "hide-default-header": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "no-data",
                              fn: function () {
                                return [_c("div", { attrs: { id: "no-data" } })]
                              },
                              proxy: true,
                            },
                            {
                              key: "item",
                              fn: function ({ expand, isExpanded, item }) {
                                return [
                                  _c(
                                    "tr",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return expand(!isExpanded)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "name-row pt-3",
                                              attrs: {
                                                "no-gutters": "",
                                                "align-content": "center",
                                              },
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "row__icon",
                                                class: {
                                                  "row__icon--active":
                                                    !isExpanded,
                                                },
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "row__title" },
                                                [_vm._v(_vm._s(item.GroupName))]
                                              ),
                                              !isExpanded
                                                ? _c("div", {
                                                    staticClass: "row--edit",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.onEditItem(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "expanded-item",
                              fn: function (props) {
                                return [
                                  _c("UpsellGroupExpansion", {
                                    attrs: { items: props.item.items },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          89675376
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }